import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form-new';

import {
    UserTestState,
    actions,
    UserTestErrors,
    getNextQuestion,
    TestQuestion,
    getPrevQuestion,
    FormFieldAnswers,
} from '../store';
import { ApplicationState } from '../../../store';
import { QuestionView } from './question-view/QuestionView';
import { TestTimer } from './test-timer/TestTimer';
import { withLoading, Theme, theme } from 'utils';
import { TestQuestionsList } from './test-questions-list';
import { ShowQuestionsButton } from './show-questions-button/ShowQuestionsButton';
import { Page } from 'components';

interface OwnProps {
    changeLightTheme: (newTheme: Theme) => void;
}

export type PassingTestProps = UserTestState &
    typeof actions &
    OwnProps & {
        isGoToResult: boolean;
        prevQuestion?: TestQuestion;
        nextQuestion?: TestQuestion;
    };

export const PassingTest = (props: PassingTestProps) => {
    const {
        currentPassingTest,
        currentQuestion,
        setCurrentQuestion,
        finishTest,
        isGoToResult,
        changeLightTheme,
        isLoading,
    } = props;
    const { passingTestQuestionOptions, questionTitle, id: currentQuestionId } = currentQuestion || {};
    const { passingTestQuestions, secondsLeft } = currentPassingTest;
    const { questionId } = useParams<{ questionId: string }>();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const methods = useForm<FormFieldAnswers>({
        defaultValues: {
            answers: passingTestQuestionOptions ?? [],
        },
    });
    const { reset } = methods;
    const [showQuestionNumbers, setShowQuestionNumbers] = useState(false);

    const goToResult = useCallback(
        (state?: Record<string, unknown>) => {
            push(pathname.replace(`passing/${questionId}`, `finish`), {
                ...state,
            });
        },
        [pathname, push, questionId]
    );

    const switchToQuestion = useCallback(
        (newQuestionId: number) => {
            if (questionId && newQuestionId) {
                push(pathname.replace(questionId, newQuestionId.toString()));
            }
        },
        [pathname, push, questionId]
    );

    useEffect(() => {
        if (questionId) {
            if (+questionId !== currentQuestionId) {
                setCurrentQuestion(+questionId);
            }
        }
    }, [questionId]);

    useEffect(() => {
        reset({
            answers: passingTestQuestionOptions ?? [],
        });
    }, [currentQuestionId]);

    useEffect(() => {
        if (isGoToResult) {
            goToResult();
        }
    }, [goToResult, isGoToResult]);

    useEffect(() => {
        if (showQuestionNumbers) {
            return () => changeLightTheme(theme.light);
        }
    }, [changeLightTheme, showQuestionNumbers]);

    const handleQuestionView = (): void => {
        setShowQuestionNumbers(!showQuestionNumbers);
    };

    const handleEndTime = useCallback(() => {
        finishTest();
        goToResult({ isTimeExpired: true });
    }, [finishTest, goToResult]);

    if (isGoToResult || isLoading || !passingTestQuestions.length) {
        return null;
    }

    return (
        <Page>
            <div className="block-test">
                <TestTimer handleEndTime={handleEndTime} totalSeconds={secondsLeft} />
                <FormProvider {...methods}>
                    <div className="block-test-wrap">
                        <ShowQuestionsButton
                            showQuestions={showQuestionNumbers}
                            handleClick={handleQuestionView}
                            currentQuestion={currentQuestion}
                            passingTestQuestions={passingTestQuestions}
                        />
                        {showQuestionNumbers ? (
                            <TestQuestionsList
                                questions={passingTestQuestions}
                                currentQuestionId={currentQuestion.id}
                                switchToQuestion={switchToQuestion}
                                handleQuestionView={handleQuestionView}
                            />
                        ) : (
                            <QuestionView
                                {...props}
                                isLastQuestion={
                                    passingTestQuestions.filter(
                                        (x) => !x.passingTestQuestionOptions.filter((f) => f.isSelected).length
                                    ).length <= 1
                                }
                                questionTitle={questionTitle}
                                questionOptions={passingTestQuestionOptions}
                                switchToQuestion={switchToQuestion}
                            />
                        )}
                    </div>
                </FormProvider>
            </div>
        </Page>
    );
};

const mapStateToProps = ({ userTest }: ApplicationState, ownProps: OwnProps) => {
    const { isTestFinish, userTestError, currentPassingTest, currentQuestion } = userTest;

    return {
        ...userTest,
        ...ownProps,
        isGoToResult: isTestFinish || userTestError.status === UserTestErrors.PassingTestNotFound,
        nextQuestion: getNextQuestion(currentPassingTest.passingTestQuestions, currentQuestion?.id),
        prevQuestion: getPrevQuestion(currentPassingTest.passingTestQuestions, currentQuestion?.id),
    };
};

export default connect(mapStateToProps, actions)(withLoading(PassingTest) as () => JSX.Element);
