import { CertDirection } from 'enums/cert-direction';
import { Action, Reducer } from 'redux';
import { StudyGroupTestCondition, StudyGroupTestConditionPassed } from '../../../models/study-group-test-condition';
import { UserTestStatistic } from '../../../models/test/user-test-statistic';

import {
    ActionTypes,
    CLEAR_MESSAGE_USER_TEST,
    CLEAR_STORE,
    CLEAR_STUDY_GROUP,
    CLEAR_TEST,
    FINISH_TEST,
    GET_TEST_RESULT,
    GET_TRAINING_GROUP_CODE,
    SET_ANSWER,
    SET_CURRENT_PASSING_TEST,
    SET_CURRENT_QUESTION,
    SET_ERROR_USER_TEST,
    SET_IS_QUIT,
    SET_LOADING,
    SET_TEST_PASSED,
} from './actions';

export interface FormFieldAnswers {
    answers: QuestionOption[];
}

export enum LevelOfEvaluation {
    Fail = 2,
    Lowest = 3,
    Middle = 4,
    Highest = 5,
}

export enum UserTestErrors {
    PassingTestAlreadyStarted = '1',
    PassingTestNotFound = '3',
    TestAlreadyCompleted = '4',
    QuestionAlreadyAnswered = '5',
}

export interface Error {
    status: string;
    message: string;
}

export interface QuestionOption {
    questionOptionId: number;
    questionOptionText: string;
    isSelected: boolean;
}

export interface TestQuestion {
    id: number;
    questionTitle: string;
    passingTestQuestionOptions: QuestionOption[];
    isMulti: boolean;
    rightCount?: number;
}

export interface PassingTest {
    id: number;
    attemptNo: number;
    secondsLeft: number;
    passingTestQuestions: TestQuestion[];
    enableEditName: boolean;
}

export interface CategoryResult {
    categoryName: string;
    quantityOfCorrectAnswers: number;
    quantityOfQuestions: number;
}

export interface TestResultDetails {
    totalQuantityOfCorrectAnswers: number;
    totalQuantityOfQuestions: number;
    resultByCategories: CategoryResult[];
}

export interface TestResult {
    testTitle: string;
    attemptNo: number;
    quantityPoints: number;
    maxQuantityPoints: number;
    levelOfEvaluation: LevelOfEvaluation;
    descriptionOfEvaluation: string;
    isGiveCertificate: boolean;
    direction: CertDirection;
}

export interface UserTestState {
    code: string;
    zipCoded: boolean;
    isTestFinish: boolean;
    isTestStart: boolean;
    isLoading: boolean;
    isTestQuit: boolean;
    userTestSuccess: string;
    userTestError: Error;
    studyGroupDescription?: StudyGroupTestCondition;
    currentQuestion: TestQuestion;
    currentPassingTest: PassingTest;
    testResult?: TestResult;
    testResultDetails?: TestResultDetails;
    testStatistic?: UserTestStatistic;
    passedTestCondition?: StudyGroupTestConditionPassed;
}

const initialQuestion = {
    passingTestQuestionOptions: [] as QuestionOption[],
} as TestQuestion;

const initialState: Readonly<UserTestState> = {
    code: '',
    zipCoded: false,
    isTestStart: false,
    isTestFinish: false,
    isLoading: false,
    isTestQuit: false,
    userTestSuccess: '',
    userTestError: { status: '', message: '' },
    currentPassingTest: {
        passingTestQuestions: [] as TestQuestion[],
        enableEditName: true,
    } as PassingTest,
    currentQuestion: initialQuestion,
};

export const reducer: Reducer<UserTestState> = (
    state: UserTestState = initialState,
    incomingAction: Action
): UserTestState => {
    const action = incomingAction as ActionTypes;
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading === undefined || action.isLoading,
            };
        case SET_ANSWER:
            return {
                ...state,
                currentPassingTest: {
                    ...state.currentPassingTest,
                    passingTestQuestions: action.questions,
                },
            };
        case SET_ERROR_USER_TEST:
            return {
                ...state,
                userTestError: action.userTestError,
                isLoading: false,
            };
        case SET_TEST_PASSED:
            return {
                ...state,
                passedTestCondition: action.testCondition,
            };
        case CLEAR_MESSAGE_USER_TEST:
            return {
                ...state,
                userTestSuccess: '',
                userTestError: { status: '', message: '' },
            };
        case GET_TRAINING_GROUP_CODE:
            return {
                ...state,
                studyGroupDescription: action.studyGroupDescription,
                code: action.code,
                zipCoded: action.zipCoded,
                isLoading: false,
            };
        case SET_CURRENT_PASSING_TEST:
            return {
                ...state,
                isTestStart: true,
                isTestFinish: false,
                currentPassingTest: action.currentPassingTest,
                currentQuestion: action.currentQuestion,
                isLoading: false,
            };
        case SET_CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.currentQuestion,
                isLoading: false,
            };
        case FINISH_TEST:
            return {
                ...state,
                isTestFinish: true,
            };
        case GET_TEST_RESULT:
            return {
                ...state,
                testResult: action.testResult,
                testResultDetails: action.testResultDetails,
                testStatistic: action.testStatistic,
                isLoading: false,
            };
        case SET_IS_QUIT:
            return {
                ...state,
                isTestQuit: true,
            };
        case CLEAR_TEST: {
            return {
                ...initialState,
                studyGroupDescription: state.studyGroupDescription,
                code: state.code,
                zipCoded: state.zipCoded,
                currentPassingTest: {
                    passingTestQuestions: [] as TestQuestion[],
                    enableEditName: state.currentPassingTest.enableEditName,
                } as PassingTest,
            };
        }
        case CLEAR_STUDY_GROUP: {
            return {
                ...state,
                passedTestCondition: undefined,
                studyGroupDescription: undefined,
            };
        }
        case CLEAR_STORE: {
            return {
                ...initialState,
                currentPassingTest: {
                    passingTestQuestions: [] as TestQuestion[],
                    enableEditName: state.currentPassingTest.enableEditName,
                } as PassingTest,
            };
        }
        default:
            return state;
    }
};
