import React from 'react';
import DOMPurify from 'dompurify';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form-new';

import { FormFieldAnswers, QuestionOption } from '../../../store';
import { ANSWERS_LETTERS } from 'pages/questions/constants';
import { QUESTION_INPUT_NAME } from '../QuestionView';

type QuestionOptionProps = {
    options: QuestionOption[];
    disable?: boolean;
    type: 'radio' | 'checkbox';
    maxCount?: number;
};

export const QuestionMulti = (props: QuestionOptionProps) => {
    const { disable, type, maxCount } = props;
    const { formState, control, setValue, watch } = useFormContext<FormFieldAnswers>();
    const { fields } = useFieldArray<FormFieldAnswers, 'answers'>({
        control,
        name: 'answers',
    });
    const { isSubmitting } = formState;

    const fillMaxCount = (optionId: number): boolean =>
        maxCount !== undefined &&
        watch().answers.filter((f) => f.isSelected).length >= maxCount &&
        (!watch().answers.find((r) => r.questionOptionId === optionId)?.isSelected ?? false);

    return (
        <>
            {fields.map((opt, index) => (
                <div key={opt.questionOptionId} className="question-item-wrap">
                    <label className="question-item">
                        <Controller
                            name={`answers.${index}.isSelected`}
                            control={control}
                            disabled={isSubmitting || (disable && fillMaxCount(opt.questionOptionId))}
                            defaultValue={opt.isSelected}
                            render={({ field: { onChange, value, ...props } }) => (
                                <input
                                    type={'checkbox'}
                                    id={QUESTION_INPUT_NAME}
                                    className="question-item-input"
                                    {...props}
                                    checked={value}
                                    onChange={(e) => {
                                        if (type === 'radio') {
                                            fields.map((_, i) => {
                                                if (i !== index) {
                                                    setValue(`answers.${i}.isSelected`, false);
                                                }
                                            });
                                        }
                                        onChange(e.target.checked);
                                    }}
                                />
                            )}
                        />
                        <span className="question-item-value">{ANSWERS_LETTERS.split('')[index]}</span>
                        <span
                            className="question-item-text"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(opt.questionOptionText),
                            }}
                        />
                    </label>
                </div>
            ))}
        </>
    );
};
