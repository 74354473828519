import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container, Column, Button, Page, Checkbox } from 'components';
import { ApplicationState } from 'store';
import { actions } from 'pages/info/store/actions';
import Captcha from '../../../components/Captcha/Captcha';
import { UserInfoState, UserData } from '../store/store';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';
import DOMPurify from 'isomorphic-dompurify';

import {
    TERMS_OF_USE_FULL_PATH,
    PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH,
    PRIVACY_STATEMENT_FULL_PATH,
    PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH,
} from 'global-constants';
import { withLoading } from 'utils';
import TermsOfUse from '../../application-policies/terms-of-use/TermsOfUsePage';
import PrivacyStatementBase from '../../application-policies/privacy-statement/PrivacyBaseComponent';
import { AppSettingsState } from 'store/app-settings/store';
import { LanguageKeys } from 'models/language/language';
import { language } from 'constants/language';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

type ConfirmTermsOfUseFormData = UserData & {
    personalAgreement: boolean;
    marketingAgreement: boolean;
    personalCheckBoxText: string;
    personalText: string;
    marketingCheckBoxText: string;
    marketingText: string;
};

type ConfirmTermsOfUseProps = UserInfoState &
    UserAuthenticationState &
    AppSettingsState &
    typeof userAuthenticationActions &
    typeof actions;

const ConfirmTermsOfUseOnlyAgreements = (props: ConfirmTermsOfUseProps) => {
    const { user, userNameError, addUserName, loadUser, clearMessages, marketingVersion, personalVersion } = props;
    const alert = useAlert();
    const [t, i18n] = useTranslation(['info', 'validation']);
    const currentLanguage = i18n.language as LanguageKeys;

    const { register, handleSubmit, errors } = useForm<ConfirmTermsOfUseFormData>();

    const captchaRef = useRef<ReCAPTCHA>(null);

    const refMarketing = useRef<HTMLDivElement>(null);
    const refPersonal = useRef<HTMLDivElement>(null);

    const handleSubmitForm = handleSubmit(async (values) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        await addUserName({
            ...values,
            lastName: user?.lastName ?? '',
            middleName: user?.middleName ?? '',
            userName: user?.userName ?? '',
            company: user?.company ?? '',
            captchaCode: captchaCode || '',
            countryId,
            personalCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:data-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:data-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            personalText: refPersonal?.current?.innerHTML as string,
            marketingCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:marketing-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:marketing-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            marketingText: refMarketing?.current?.innerHTML as string,
            marketingVersion,
            personalVersion,
        });
        loadUser();
    });

    useEffect(() => {
        if (userNameError && alert) {
            alert.error(userNameError);
        }
        return () => {
            clearMessages();
        };
    }, [userNameError, alert, clearMessages]);

    const [countryId] = useState(user?.country?.id ?? 1);

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="100" className="block-center code-column">
                    <Captcha ref={captchaRef} />
                    <form onSubmit={handleSubmitForm} autoComplete="off" className="info-form">
                        <div>
                            <div className="confirm-term-of-use">
                                <Checkbox
                                    additionalClasses={errors?.personalAgreement && 'error'}
                                    id="personalAgreement"
                                    name="personalAgreement"
                                    inputRef={register({
                                        required: {
                                            value: true,
                                            message: '',
                                        },
                                    })}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            currentLanguage === language.ru.key
                                                ? t('info:data-consent-agreement-with-link', {
                                                      dataConsentLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                                  })
                                                : t('info:data-consent-agreement2', {
                                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                                  })
                                        ),
                                    }}
                                />
                            </div>
                            <div className="confirm-term-of-use">
                                <Checkbox id="marketingAgreement" name="marketingAgreement" inputRef={register} />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            currentLanguage === language.ru.key
                                                ? t('info:marketing-consent-agreement-with-link', {
                                                      dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                                  })
                                                : t('info:marketing-consent-agreement2', {
                                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                                  })
                                        ),
                                    }}
                                />
                            </div>
                            <div className="confirm-term-of-use">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            t('save-button-press', {
                                                touLink: `/${TERMS_OF_USE_FULL_PATH}`,
                                            })
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="info-buttons d-flex justify-content-space-between">
                            <Button type="submit" buttonClass="primary">
                                {t('save-button')}
                            </Button>
                        </div>
                    </form>
                </Column>
            </Container>
            <div className="hidden" ref={refPersonal}>
                <TermsOfUse />
            </div>
            <div className="hidden" ref={refMarketing}>
                <PrivacyStatementBase />
            </div>
        </Page>
    );
};

export default connect(
    ({ userInfo, userAuthentication, appSettings }: ApplicationState) => ({
        ...userInfo,
        ...userAuthentication,
        ...appSettings,
        isLoading: userInfo.isLoading || userAuthentication?.isLoading,
    }),
    { ...actions, ...userAuthenticationActions }
)(withLoading(ConfirmTermsOfUseOnlyAgreements) as () => JSX.Element);
