import { AppThunkAction } from 'store';
import api from 'api';
import { StudyGroupTestCondition, StudyGroupTestConditionPassed } from '../../../models/study-group-test-condition';
import { UserTestStatistic } from '../../../models/test/user-test-statistic';
import { TestService } from '../../../services/test.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import {
    Error,
    PassingTest,
    TestQuestion,
    QuestionOption,
    TestResult,
    TestResultDetails,
    UserTestErrors,
} from './store';
import { CertDirection } from 'enums/cert-direction';

export const GET_TRAINING_GROUP_CODE = 'userTest/GET_TRAINING_GROUP_CODE';
export const SET_TEST_PASSED = 'userTest/SET_TEST_PASSED';
export const SET_LOADING = 'userTest/SET_LOADING';
export const SET_ERROR_USER_TEST = 'userTest/SET_ERROR_USER_TEST';
export const CLEAR_MESSAGE_USER_TEST = 'userTest/CLEAR_MESSAGE_USER_TEST';
export const SET_ENTER_CODE_ERROR = 'userTest/SET_ENTER_CODE_ERROR';
export const SET_CURRENT_PASSING_TEST = 'userTest/SET_CURRENT_PASSING_TEST';
export const SET_CURRENT_QUESTION = 'userTest/SET_CURRENT_QUESTION';
export const SET_ANSWER = 'userTest/SET_ANSWER';
export const FINISH_TEST = 'userTest/FINISH_TEST';
export const GET_TEST_RESULT = 'userTest/GET_TEST_RESULT';
export const SET_IS_QUIT = 'userTest/SET_IS_QUIT';
export const CLEAR_TEST = 'userTest/CLEAR_TEST';
export const CLEAR_STORE = 'userTest/CLEAR_STORE';
export const CLEAR_STUDY_GROUP = 'userTest/CLEAR_STUDY_GROUP';

export interface SetLoading {
    type: typeof SET_LOADING;
    isLoading?: boolean;
}

export interface SetIsQuit {
    type: typeof SET_IS_QUIT;
}

export interface FinishTest {
    type: typeof FINISH_TEST;
}

export interface GetTestResult {
    type: typeof GET_TEST_RESULT;
    testResult: TestResult;
    testResultDetails?: TestResultDetails;
    testStatistic?: UserTestStatistic;
}

export interface SetCurrentPassingTest {
    type: typeof SET_CURRENT_PASSING_TEST;
    currentPassingTest: PassingTest;
    currentQuestion: TestQuestion;
}

export interface SetCurrentQuestion {
    type: typeof SET_CURRENT_QUESTION;
    currentQuestion: TestQuestion;
}

export interface SetAnswer {
    type: typeof SET_ANSWER;
    questions: TestQuestion[];
    selectedQuestionOptionsId?: number[];
    passingTestQuestionId?: number;
}

export interface GetTrainingGroupCode {
    type: typeof GET_TRAINING_GROUP_CODE;
    studyGroupDescription: StudyGroupTestCondition;
    code: string;
    zipCoded: boolean;
}

export interface SetTestPassed {
    type: typeof SET_TEST_PASSED;
    testCondition: StudyGroupTestConditionPassed;
}

export interface SetErrorUserTest {
    type: typeof SET_ERROR_USER_TEST;
    userTestError: Error;
}

export interface ClearMessage {
    type: typeof CLEAR_MESSAGE_USER_TEST;
}

export interface ClearTest {
    type: typeof CLEAR_TEST;
}

export interface ClearStore {
    type: typeof CLEAR_STORE;
}

export interface ClearStudyGroup {
    type: typeof CLEAR_STUDY_GROUP;
}

export type ActionTypes =
    | SetLoading
    | GetTrainingGroupCode
    | SetTestPassed
    | SetErrorUserTest
    | ClearMessage
    | SetCurrentPassingTest
    | SetAnswer
    | SetCurrentQuestion
    | FinishTest
    | GetTestResult
    | SetIsQuit
    | ClearTest
    | ClearStore
    | ClearStudyGroup;

const finishTest = (): AppThunkAction<ActionTypes> => async (dispatch) => {
    try {
        await api.put('PassingTest/Finish');

        dispatch({
            type: FINISH_TEST,
        });
    } catch {
        dispatch({
            type: SET_LOADING,
            isLoading: false,
        });
    }
};

const startTest = (): AppThunkAction<ActionTypes> => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_LOADING,
        });
        const { userTest } = getState();

        const currentPassingTest = (
            await api.post<PassingTest>(`PassingTest/Start`, {
                code: userTest.code,
                zipCoded: userTest.zipCoded,
            })
        ).data;

        dispatch({
            type: SET_CURRENT_PASSING_TEST,
            currentPassingTest,
            currentQuestion: currentPassingTest.passingTestQuestions[0],
        });
    } catch (error: any) {
        const setError = () => {
            dispatch({
                type: SET_ERROR_USER_TEST,
                userTestError: {
                    status: 'Error',
                    message: '',
                },
            });
        };

        const errors = Object.values(error?.response?.data?.errors)?.[0] as string[];

        if (errors?.includes(UserTestErrors.PassingTestAlreadyStarted)) {
            try {
                const currentPassingTest = (await api.get<PassingTest>(`PassingTest`)).data;

                const questionsWithoutAnswer = currentPassingTest.passingTestQuestions.filter(
                    (x) => !x.passingTestQuestionOptions.filter((f) => f.isSelected).length
                );

                if (questionsWithoutAnswer.length) {
                    dispatch({
                        type: SET_CURRENT_PASSING_TEST,
                        currentPassingTest,
                        currentQuestion: getCurrentQuestion(currentPassingTest.passingTestQuestions),
                    });
                } else {
                    await api.put('PassingTest/Finish');
                    await startTest()(dispatch, getState);
                }
            } catch {
                await api.put('PassingTest/Finish');
                setError();
            }
        }
        setError();
    }
};

export const actions = {
    startTest,
    getTrainingGroup:
        (code: string, skipCheckCert?: boolean, zipCoded?: boolean): AppThunkAction<ActionTypes> =>
        async (dispatch) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const testConditionResponse = await TrainingGroupService.getConditionsByCode(
                    code,
                    skipCheckCert,
                    zipCoded
                );
                const testCondition = testConditionResponse.data;

                if ('statusCode' in testCondition) {
                    dispatch({
                        type: SET_TEST_PASSED,
                        testCondition: testCondition,
                    });

                    dispatch({
                        type: SET_ERROR_USER_TEST,
                        userTestError: {
                            status: testCondition.statusCode.toString(),
                            message: 'test passed',
                        },
                    });

                    return;
                }

                dispatch({
                    type: GET_TRAINING_GROUP_CODE,
                    studyGroupDescription: testCondition,
                    code,
                    zipCoded: zipCoded ?? false,
                });
            } catch (error: any) {
                dispatch({
                    type: SET_ERROR_USER_TEST,
                    userTestError: {
                        status: error.response?.status,
                        message: `${error.response?.status} error`,
                    },
                });
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    setAnswer:
        (passingTestQuestionId: number, answers: number[]): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            const { currentPassingTest, isTestStart, isTestFinish } = getState().userTest;
            const passingTestQuestions = markQuestionWithAnswers(
                currentPassingTest.passingTestQuestions,
                answers,
                passingTestQuestionId
            );
            const questionsWithoutAnswer = passingTestQuestions.filter(
                (x) => !x.passingTestQuestionOptions.filter((f) => f.isSelected).length
            );

            if (!isTestFinish && isTestStart && !questionsWithoutAnswer.length) {
                dispatch({
                    type: SET_LOADING,
                });
            }
            try {
                await api.put(`PassingTest/SetAnswer`, {
                    passingTestQuestionId,
                    selectedQuestionOptionsId: answers,
                });

                dispatch({
                    type: SET_ANSWER,
                    questions: passingTestQuestions,
                });
            } catch (error: any) {
                if (error?.response?.data?.errors) {
                    const errors = Object.values(error?.response?.data?.errors)?.[0] as string[];

                    if (errors?.includes(UserTestErrors.QuestionAlreadyAnswered)) {
                        dispatch({
                            type: SET_ANSWER,
                            questions: passingTestQuestions,
                        });
                    }

                    if (errors?.includes(UserTestErrors.TestAlreadyCompleted)) {
                        dispatch({
                            type: FINISH_TEST,
                        });
                    }
                }
            } finally {
                if (!isTestFinish && isTestStart && !questionsWithoutAnswer.length) {
                    finishTest()(dispatch, getState);
                }
            }
        },
    getPassingTest: (): AppThunkAction<ActionTypes> => async (dispatch) => {
        try {
            dispatch({
                type: SET_LOADING,
            });

            const currentPassingTest = (await api.get<PassingTest>(`PassingTest`)).data;

            dispatch({
                type: SET_CURRENT_PASSING_TEST,
                currentPassingTest: currentPassingTest,
                currentQuestion: getCurrentQuestion(currentPassingTest.passingTestQuestions),
            });
        } catch (error: any) {
            dispatch({
                type: SET_ERROR_USER_TEST,
                userTestError: {
                    status: error?.response?.data?.toString(),
                    message: '',
                },
            });
        }
    },
    setCurrentQuestion:
        (questionId: number): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                const currentPassingTest = getState().userTest.currentPassingTest;
                const currentQuestion = currentPassingTest.passingTestQuestions.find((x) => x.id === questionId);
                if (currentQuestion) {
                    dispatch({
                        type: SET_CURRENT_QUESTION,
                        currentQuestion,
                    });
                } else {
                    const questionsWithoutAnswer = currentPassingTest.passingTestQuestions.filter(
                        (x) => !x.passingTestQuestionOptions.filter((f) => f.isSelected).length
                    );
                    dispatch({
                        type: SET_CURRENT_QUESTION,
                        currentQuestion: questionsWithoutAnswer[0],
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
    getTestResult:
        (passingTestId: number): AppThunkAction<ActionTypes> =>
        async (dispatch, getState) => {
            try {
                dispatch({
                    type: SET_LOADING,
                });

                const user = getState().userAuthentication?.user;

                const testResult = (await api.get<TestResult>(`PassingTest/${passingTestId}`)).data;
                let testResultDetails;
                let testStatistic;

                if (user?.isStudentStudyCenter || testResult?.direction === CertDirection.ProductLK) {
                    testStatistic = (await TestService.getStatisticForUser(passingTestId)).data;
                } else {
                    testResultDetails = (await api.get<TestResultDetails>(`ResultTest/${passingTestId}`)).data;
                }

                dispatch({
                    type: GET_TEST_RESULT,
                    testResult: testResult || ({} as TestResult),
                    testResultDetails,
                    testStatistic,
                });
            } catch (error) {
                console.error(error);
                dispatch({
                    type: SET_LOADING,
                    isLoading: false,
                });
            }
        },
    setIsQuit: () => ({ type: SET_IS_QUIT }),
    clearMessage: () => ({ type: CLEAR_MESSAGE_USER_TEST }),
    clearTest: () => ({ type: CLEAR_TEST }),
    clearStore: () => ({ type: CLEAR_STORE }),
    clearStudyGroup: () => ({ type: CLEAR_STUDY_GROUP }),
    finishTest,
};

const getCurrentQuestion = (questions: TestQuestion[]) =>
    questions.find((x) => !x.passingTestQuestionOptions.filter((f) => f.isSelected).length) ||
    ({
        passingTestQuestionOptions: [] as QuestionOption[],
    } as TestQuestion);

export const getNextQuestion = (questions: TestQuestion[], questionId: number) => {
    if (questions.length) {
        const currentQuestionIndex = questions.findIndex((x) => x.id === questionId);
        for (let idx = currentQuestionIndex + 1; idx < questions.length; idx++) {
            if (!questions[idx].passingTestQuestionOptions.filter((f) => f.isSelected).length) return questions[idx];
        }
        for (let idx = 0; idx < currentQuestionIndex - 1 && currentQuestionIndex - 1 >= 0; idx++) {
            if (!questions[idx].passingTestQuestionOptions.filter((f) => f.isSelected).length) return questions[idx];
        }
    }

    return;
};

export const getPrevQuestion = (questions: TestQuestion[], questionId: number) => {
    if (questions.length) {
        const currentQuestionIndex = questions.findIndex((x) => x.id === questionId);
        for (let idx = currentQuestionIndex - 1; idx >= 0; idx--) {
            if (!questions[idx].passingTestQuestionOptions.filter((f) => f.isSelected).length) return questions[idx];
        }
        for (
            let idx = questions.length - 1;
            idx > currentQuestionIndex + 1 && currentQuestionIndex + 1 < questions.length;
            idx--
        ) {
            if (!questions[idx].passingTestQuestionOptions.filter((f) => f.isSelected).length) return questions[idx];
        }
    }

    return;
};

const markQuestionWithAnswers = (questions: TestQuestion[], answers: number[], id: number) =>
    questions.map((q) => {
        if (q.id !== id) {
            return q;
        }

        return {
            ...q,
            passingTestQuestionOptions: q.passingTestQuestionOptions.map((p) => {
                p.isSelected = answers.includes(p.questionOptionId);
                return { ...p };
            }),
        };
    });
