import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'isomorphic-dompurify';

import { QuestionMulti } from './test-question/QuestionMulti';
import { FormFieldAnswers, QuestionOption as ServerQuestionOption, TestQuestion } from '../../store';
import { QuestionViewButtons } from './question-view-buttons/QuestionViewButtons';

export const QUESTION_INPUT_NAME = 'selectedQuestionOptionId';

interface QuestionViewProps {
    questionTitle: string;
    questionOptions: ServerQuestionOption[];
    currentQuestion: TestQuestion;
    setIsQuit: () => void;
    finishTest: () => void;
    setAnswer: (passingTestQuestionId: number, answers: number[]) => void;
    switchToQuestion: (newQuestionId: number) => void;
    nextQuestion?: TestQuestion;
    prevQuestion?: TestQuestion;
    isLastQuestion: boolean;
}

export const QuestionView = (props: QuestionViewProps) => {
    const {
        questionOptions,
        questionTitle,
        setIsQuit,
        currentQuestion,
        finishTest,
        switchToQuestion,
        setAnswer,
        nextQuestion,
        prevQuestion,
        isLastQuestion,
    } = props;
    const { t } = useTranslation('user-test');
    const { handleSubmit, reset, watch, formState } = useFormContext<FormFieldAnswers>();
    const { isDirty } = formState;

    const handleSubmitAnswer = async (response: FormFieldAnswers) => {
        const testAnswers = response.answers.filter((f) => f.isSelected).map((m) => m.questionOptionId);
        if (testAnswers.length && isDirty) {
            await setAnswer(+currentQuestion.id, testAnswers);
        }
        if (nextQuestion) {
            switchToQuestion(nextQuestion.id);
        }
    };

    useEffect(() => {
        reset();
    }, [currentQuestion]);

    const handleBack = () => {
        if (prevQuestion) {
            switchToQuestion(prevQuestion.id);
        }
    };

    const handleFinish = () => {
        finishTest();
        setIsQuit();
    };
    const isButtonDisabled = () =>
        currentQuestion.isMulti && currentQuestion.rightCount && isDirty
            ? watch().answers.filter((f) => f.isSelected).length < currentQuestion.rightCount
            : !currentQuestion.isMulti && isDirty
            ? !watch().answers.filter((f) => f.isSelected).length
            : false;

    return (
        <div className="block-test-body">
            <h2
                className="test-body-title"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(questionTitle || ''),
                }}
            />
            <p className="test-body-remark">
                {currentQuestion.rightCount === 1 || !currentQuestion.rightCount
                    ? t('choose-option-one')
                    : t('choose-option-right', { count: currentQuestion.rightCount })}
            </p>
            <form onSubmit={handleSubmit(handleSubmitAnswer)}>
                <div className="test-body-question">
                    {currentQuestion.isMulti ? (
                        <QuestionMulti
                            options={questionOptions}
                            maxCount={currentQuestion.rightCount}
                            disable
                            type={'checkbox'}
                        />
                    ) : (
                        <QuestionMulti options={questionOptions} type={'radio'} />
                    )}
                </div>
                <QuestionViewButtons
                    isHavePrevQuestions={!!prevQuestion}
                    isLastQuestion={isLastQuestion}
                    handleBack={handleBack}
                    handleFinish={handleFinish}
                    isDisabled={isButtonDisabled()}
                />
            </form>
        </div>
    );
};
